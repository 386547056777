@import "../config";

@include exports("modules/Software") {
    @include non-critical() {
        .software {
            background-image: url('../../../img/landingpage/pure-base-501-lp-background-o.jpg');
            background-position: top center;
            background-repeat: repeat-y;
            background-size: 100% auto;
            &-header {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                background-image: url('https://www.bequiet.com/admin/ImageServer.php?ID=64958@be-quiet.net&omitPreview=true');
                background-position: center center;                
                background-repeat: no-repeat;
                background-size: cover;
                padding-top: 3rem;
                padding-bottom: 3rem;
                @media (min-width: 992px) {
                    min-height: 90vh;
                    background-image: url('https://www.bequiet.com/admin/ImageServer.php?ID=64957@be-quiet.net&omitPreview=true');
                }
                &-logo {
                    img {
                        display: block;
                        width: 320px;
                        height: auto;
                        margin: 0 auto;
                    }
                }
                span {
                    display: block;
                    font-size: 14px;
                }
            }
            &-tutorial-videos {
                background: #000;
            }
            &-hardware-with-io-center {
                background: rgb(15,15,15);
                background: linear-gradient(90deg, rgba(15,15,15,1) 0%, rgba(25,25,25,1) 100%);

            }
            &-features {
                &-box {
                    position: relative;
                    &-wrapper {
                        cursor: pointer;
                        position: absolute;
                        // display: flex;
                        // justify-content: center;
                        // flex-direction: column;
                        // text-align: center;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        z-index: 20;
                        padding: 18px;                        
                        .software-features-box-title {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            text-transform: uppercase;
                            font-weight: 700;
                            padding: 0;
                            margin: 0;
                            white-space: nowrap;
                            transition: all 0.5s ease-in-out;
                        }
                        .software-features-box-text {
                            position: absolute;
                            top: 68px;
                            left: 18px;
                            opacity: 0;
                            transition: opacity 0.5s ease-in-out;
                            ul {
                                li {
                                    margin-bottom: 1rem;
                                }
                            }
                        }
                        .software-features-box-btn {
                            position: absolute;
                            display: block;
                            border: none;
                            width: 32px;
                            height: 32px;
                            color: #fff;
                            background: #ee7f00;
                            padding: 0;
                            top: 60%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            -webkit-border-radius: 16px;
                            -moz-border-radius: 16px;
                            border-radius: 16px;
                            transition: all 0.5s ease-in-out;
                            .icon:before {
                                @include iconfont-icon(plus);
                            }                    
                        }
                        &:after {
                            content: '';
                            position: absolute;                            
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            opacity: 0;
                            background-color: rgba(0,0,0,0.60);
                            -webkit-box-shadow: inset 0px 0px 48px 0px rgba(0,0,0,0.70);
                            -moz-box-shadow: inset 0px 0px 48px 0px rgba(0,0,0,0.70);
                            box-shadow: inset 0px 0px 48px 0px rgba(0,0,0,0.70);
                            z-index: -1;

                            transition: opacity 0.3s ease-in-out;
                        }
                        &:hover {
                            // justify-content: space-between;
                            // text-align: left;
                            &:after {
                                opacity: 1;
                            }
                            .software-features-box-title {
                                top: 18px;
                                left: 18px;
                                transform: translate(0,0);
                            }
                            .software-features-box-text {
                                opacity: 1;
                            }
                            .software-features-box-btn {
                                left: 18px;
                                top: calc(100% - 50px);
                                transform: translate(0,0);
                                .icon:before {
                                    @include iconfont-icon(minus);
                                }
                            }
                        }                        
                    }
                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                        z-index: 10;
                    }
                }
            }
            &-hardware {
                &-box {
                    position: relative;
                    &-content {
                        position: absolute;
                        display: block;                        
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 24px;
                        font-weight: 200;
                        z-index: 10;
                        transition: color 0.3s ease-in-out;
                        span {
                            display: block;
                        }
                        strong {
                            font-weight: 700;
                        }
                        &:after {
                            content: '';
                            position: absolute;                            
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            opacity: 0;
                            background-color: rgba(0,0,0,0.60);
                            -webkit-box-shadow: inset 0px 0px 48px 0px rgba(0,0,0,0.70);
                            -moz-box-shadow: inset 0px 0px 48px 0px rgba(0,0,0,0.70);
                            box-shadow: inset 0px 0px 48px 0px rgba(0,0,0,0.70);
                            z-index: -1;
                            transition: opacity 0.3s ease-in-out;
                        }
                        &:hover {
                            &:after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            &-io-center {
                h3 {
                    margin-top: 0;
                    margin-bottom: 1rem;
                }
                p {
                    display: block;
                }
                p :last-child {
                    margin-bottom: 2rem;
                }
            }
            &-background-color {
                color: #ccc;
                background: rgb(15,15,15);
                background: linear-gradient(90deg, rgba(15,15,15,1) 0%, rgba(25,25,25,1) 100%);
            }
            &-pm-fix {
                .row {
                    margin-left: 0;
                    margin-right: 0;
                }
                .col-lg-6 {
                    padding-left: 0;
                    padding-right: 0;
                }
            }    
            &-headline {
                display: block;
                color: #fff;
                text-transform: uppercase;
                font-weight: 800;
                font-size: 2.7777777778em;
                line-height: 1;
            }
            .btn-cta {                    
                background: rgb(235,98,18);
                background: linear-gradient(90deg, rgba(235,98,18,1) 0%, rgba(239,127,1,1) 100%);
                text-transform: uppercase;
                padding: 1rem 1.5rem;
                font-size: 20px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: normal;
                // transition: all 0.25s ease;
                &:hover {
                    background: #fff;
                    color: #ee7f00;
                }
                &.medium {
                    padding: 0.75rem 1.25rem;
                    font-size: 18px;
                }
            }
            .box-border {
                border: 1px solid #777777;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                border-radius: 4px;
                overflow: hidden;
            }
        }
    }
    @include critical() {
        .col-xs-auto {
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
            padding-right: 10px;
            padding-left: 10px;
        }
        @media (min-width: 768px) {
            .col-md-auto {
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
                width: auto;
                max-width: 100%;
                padding-right: 10px;
                padding-left: 10px;
            }
        }
        @media (min-width: 992px) {
            .col-lg-auto {
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
                width: auto;
                max-width: 100%;
                padding-right: 10px;
                padding-left: 10px;
            }
        }
        @for $i from 0 through 12 {
            .order-xs-#{$i} {
                order: $i !important;
            }
        }
        @media (min-width: 992px) {
            @for $i from 0 through 12 {
                .order-lg-#{$i} {
                    order: $i !important;
                }
            }
        }
    }
}